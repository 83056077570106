import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BasicContext } from 'src/context/BasicContext';

import ErrorMsg from '../errorMsg';

const AutoCompleteGoogle = ({
  inputName,
  placeholder,
  errorText,
  className,
  label,
  labelClassName,
  required,
  onChangeFunc,
  onAddressInputChange,
  listClassName,
  isClearable,
  ...props
}: any) => {
  let autoComplete: any;
  const [inputValue, setInputValue] = useState('');
  const autoCompleteRef = useRef(null);
  const { googleKey } = useContext(BasicContext);
  let isRecheckAddress = false;

  // const checkNearByAddress = async (lat: any, lng: any): Promise<any> => {
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleKey}`
  //   );

  //   const details = {
  //     postalCode: '',
  //     city: '',
  //     state: '',
  //     country: '',
  //     address: '',
  //   };

  //   let lastLat, lastLng;

  //   if (response.ok) {
  //     const data = await response.json();

  //     if (data.status === 'OK') {
  //       for (const result of data.results) {
  //         lastLat = result.geometry.location.lat;
  //         lastLng = result.geometry.location.lng;

  //         for (const component of result.address_components) {
  //           if (component.types.includes('postal_code')) {
  //             details.postalCode =
  //               component.long_name.length > 3
  //                 ? component.long_name
  //                 : details.postalCode;
  //           } else if (component.types.includes('locality')) {
  //             details.city = component.long_name;
  //           } else if (
  //             component.types.includes('administrative_area_level_1')
  //           ) {
  //             details.state = component.long_name;
  //           } else if (component.types.includes('country')) {
  //             details.country = component.long_name;
  //           } else if (component.types.includes('premise')) {
  //             details.address += `${component.long_name} `;
  //           } else if (component.types.includes('street_number')) {
  //             details.address += `${component.long_name} `;
  //           } else if (component.types.includes('route')) {
  //             details.address += `${component.short_name} `;
  //           } else if (component.types.includes('sublocality')) {
  //             details.address += `${component.long_name} `;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (
  //     (!details.address ||
  //       !details.city ||
  //       !details.postalCode ||
  //       !details.state ||
  //       !details.country ||
  //       details.postalCode?.length < 4) &&
  //     !isRecheckAddress
  //   ) {
  //     isRecheckAddress = true;

  //     return checkNearByAddress(lastLat, lastLng);
  //   }

  //   return details;
  // };

  function extractAddressComponents(result: any, details: any) {
    result.address_components.forEach((component: any) => {
      switch (true) {
        case component.types.includes('postal_code'):
          details.postalCode =
            component.long_name.length > 3
              ? component.long_name
              : details.postalCode;
          break;
        case component.types.includes('locality'):
          details.city = component.long_name;
          break;
        case component.types.includes('administrative_area_level_1'):
          details.state = component.long_name;
          break;
        case component.types.includes('country'):
          details.country = component.long_name;
          break;
        default:
          details.address += component.types.some((type: any) =>
            ['premise', 'street_number', 'route', 'sublocality'].includes(type)
          )
            ? `${component.long_name} `
            : '';
          break;
      }
    });
  }

  const checkNearByAddress = async (
    lat: number,
    lng: number,
    retryCount = 0
  ): Promise<any> => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleKey}`
    ).catch((error) => {
      console.error('Failed to fetch geocode data:', error);
      throw new Error('Network error');
    });

    const details = {
      postalCode: '',
      city: '',
      state: '',
      country: '',
      address: '',
    };

    // let lastLat, lastLng;

    if (response.ok) {
      const data = await response.json();

      if (data.status === 'OK') {
        data.results.forEach((result: any) => {
          // lastLat = result.geometry.location.lat;
          // lastLng = result.geometry.location.lng;
          extractAddressComponents(result, details);
        });
      }
    }

    if (
      (!details.address ||
        !details.city ||
        !details.postalCode ||
        !details.state ||
        !details.country ||
        details.postalCode?.length < 4) &&
      retryCount < 3 && // Limit the number of retries to 3
      !isRecheckAddress
    ) {
      isRecheckAddress = true;

      const newLat = lat + (Math.random() - 0.5) * 0.01;
      const newLng = lng + (Math.random() - 0.5) * 0.01;

      return (
        newLat && newLng && checkNearByAddress(newLat, newLng, retryCount + 1)
      );
    }

    return details;
  };

  const handlePlaceSelect = async () => {
    const addressObject = autoComplete.getPlace();
    let latitude = addressObject.geometry.location.lat();
    let longitude = addressObject.geometry.location.lng();
    const fullAddress = addressObject.formatted_address;
    let city = '';
    let state = '';
    let postcode = '';
    let address = '';
    let country = '';
    let stateCode = '';
    let countryCode = '';
    let isCity = false;

    addressObject.address_components.forEach((component: any) => {
      const componentType = component.types;

      if (componentType.includes('premise')) {
        address += `${component.long_name} `;
      } else if (componentType.includes('street_number')) {
        address += `${component.long_name} `;
      } else if (componentType.includes('route')) {
        address += `${component.short_name} `;
      } else if (componentType.includes('sublocality')) {
        address += `${component.long_name} `;
      } else if (componentType.includes('postal_code')) {
        postcode = `${component.long_name}${postcode}`;
      } else if (componentType.includes('postal_code_suffix')) {
        postcode = `${postcode}-${component.long_name}`;
      } else if (componentType.includes('locality')) {
        city = component.long_name;
        isCity = true;
      } else if (componentType.includes('administrative_area_level_1')) {
        state = component.long_name;
        stateCode = component.short_name;
      } else if (componentType.includes('country')) {
        country = component.long_name;
        countryCode = component.short_name;
      }

      if (
        !isCity &&
        (componentType.includes('sublocality_level_1') ||
          componentType.includes('sublocality'))
      ) {
        city = component.long_name;
      }
    });

    let extraAddress;
    let address1;

    if (address) {
      extraAddress = fullAddress.substring(
        0,
        fullAddress.indexOf(address.split(' ')[0])
      );
      // extraAddress = fullAddress.split(address).shift();
      address1 = `${extraAddress} ${address}`;
    } else {
      address1 = city;
    }

    if (
      !address1 ||
      !city ||
      !postcode ||
      !state ||
      !country ||
      postcode.length < 4
    ) {
      let addressDetails: any = await checkNearByAddress(latitude, longitude);

      if (!address1) {
        address1 = addressDetails.address;
      }

      if (!city) {
        city = addressDetails.city;
      }

      if (!postcode || postcode.length < 4) {
        postcode = addressDetails.postalCode;
      }

      if (!state) {
        state = addressDetails.state;
      }

      if (!country) {
        country = addressDetails.country;
      }
    }

    const addressObj = {
      fullAddress,
      address1: address1.trim(),
      city,
      state,
      country,
      postal: postcode,
      latitude,
      longitude,
      stateCode,
      countryCode,
    };
    onAddressInputChange(addressObj.fullAddress);
    onChangeFunc(addressObj);
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setInputValue(value);
    onAddressInputChange(value);
  };

  const removeClass = () => {
    const containerElement = document.querySelectorAll('.pac-container');
    containerElement.forEach((container: any) => {
      container.classList.remove(listClassName);
    });
  };

  useEffect(() => {
    const autoCompleteDropdown = autoCompleteRef.current as any;
    setTimeout(() => {
      const containerElement = document.querySelectorAll('.pac-container');
      autoCompleteDropdown.addEventListener('focus', () => {
        if (listClassName && containerElement) {
          containerElement.forEach((container: any) => {
            container.classList.add(listClassName);
          });
        }
      });
      autoCompleteDropdown.addEventListener('blur', () => {
        if (listClassName && containerElement) {
          containerElement.forEach((container: any) => {
            container.classList.remove(listClassName);
          });
        }
      });
    }, 500);

    return () => {
      document.removeEventListener('mousedown', removeClass);
    };
  }, [autoCompleteRef]);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          // types: ['establishment'],
          componentRestrictions: { country: ['us', 'ca'] },
        }
      );
      autoComplete.setFields([
        'address_components',
        'formatted_address',
        'geometry',
      ]);
      autoComplete.addListener('place_changed', handlePlaceSelect);
      // document.addEventListener("mousedown", removeClass);
    }
    const autoCompleteDropdown = autoCompleteRef.current as any;
    setTimeout(() => {
      const containerElement = document.querySelectorAll('.pac-container');
      autoCompleteDropdown.addEventListener('focus', () => {
        if (listClassName && containerElement) {
          containerElement.forEach((container: any) => {
            container.classList.add(listClassName);
          });
        }
      });
    }, 500);

    return () => {
      document.removeEventListener('mousedown', removeClass);
    };
  }, []);

  const clearInput = () => {
    handleChange({ target: { name: inputName, value: '' } });
  };

  return (
    <div className="position-relative">
      <div className="flex">
        {label && (
          <label className={`form_label ${labelClassName}`}>{label}</label>
        )}
        {required && <span className="text-red-600 ms-[2px] leading-4">*</span>}
      </div>
      <div className="position-relative relative flex justify-center items-center">
        <input
          type="text"
          name={inputName}
          ref={autoCompleteRef}
          className={`mfnw__login-form-control ${className}`}
          placeholder={placeholder}
          onChange={handleChange}
          required={required}
          {...props}
        />
        {isClearable && inputValue !== '' && (
          <XClose
            id="clear"
            className="h-4 w-4 cursor-pointer absolute right-2 clear"
            onClick={clearInput}
          />
        )}
      </div>
      <div className="[&:empty]:mt-0 mt-1.5">
        {errorText && <ErrorMsg errorText={errorText} />}
      </div>
    </div>
  );
};

export default AutoCompleteGoogle;
