import {
  Copy06,
  InfoCircle,
  Share03,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

import ServiceDetail from './ServiceDetail';

const WithRateQuote = ({
  data,
  currency,
  addressDetails,
  isFinish,
  selectedCarrierRates,
}: any) => {
  const [isQuoted, setIsQuoted] = useState(false);
  useEffect(() => {
    setIsQuoted(data.services.some((form: any) => !form.isQuoted));
  }, [data]);

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <>
      <td
        className={`px-5 py-4 min-w-60 w-60  ${
          isQuoted ? 'bg-utilityGray100' : ''
        }`}
      >
        <div className=" flex items-center">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08]"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
              {getShortName(`${data.name}`)}
            </div>
          )}
          <div className="pl-3 max-w-48">
            {(data.name + (data.banyanPostFix || '')).length > 30 ? (
              <TooltipCmp message={`${data.name} ${data.banyanPostFix || ''}`}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">{`${
                  data.name
                } ${data.banyanPostFix || ''}`}</h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-48 flex-none">{`${
                data.name
              } ${data.banyanPostFix || ''}`}</h6>
            )}

            <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
              {data.carrierQuoteId.length > 30 ? (
                <TooltipCmp
                  message={`#${data.carrierQuoteId}`}
                  childrenClassName="max-w-40 truncate"
                >
                  <span className="max-w-40 truncate inline-block align-middle ">
                    #{data.carrierQuoteId}
                  </span>
                </TooltipCmp>
              ) : (
                <span className="inline align-middle truncate max-w-40">
                  #{data.carrierQuoteId}
                </span>
              )}
              &nbsp;
              <div className="flex gap-1">
                <TooltipCmp message="Copy">
                  <Copy06
                    onClick={(event) => {
                      event.stopPropagation();
                      copyDebounce(data.carrierQuoteId);
                    }}
                    className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                  />
                </TooltipCmp>
                {data.quoteUrl && (
                  <TooltipCmp message="Redirect">
                    <a
                      href={data.quoteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                    </a>
                  </TooltipCmp>
                )}
              </div>
            </p>
          </div>
        </div>
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        {isFinish &&
          data.tags &&
          (addressDetails.status === STATUS.OPENED ||
            addressDetails.status === STATUS.ACTIVE) &&
          data.tags[0] && (
            <BadgeCmp
              style="modern"
              type={`${data.tags[0] === 'Fastest' ? 'success' : 'error'}`}
            >
              {data.tags[0]}
            </BadgeCmp>
          )}
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        {(data.isFreightcom || data.isFreightcom === 1) && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={FreightcomIcon}
              alt="FC"
            />
            <div className="pl-3 font-medium">Freightcom</div>
          </div>
        )}
        {(data.isBanyan || data.isBanyan === 1) && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={BanyanIcon}
              alt="BANYAN"
            />
            <div className="pl-3 font-medium">Banyan</div>
          </div>
        )}
        {!data.isBanyan && !data.isFreightcom && (
          <div className="flex items-center">
            <img
              className="w-5 h-5 relative rounded-full  border-[0.75px] border-black/[0.08]"
              src={data.image ? `${data.imageUrl}${data.image}` : defaultImage}
              alt="CR"
            />
            <div className="pl-3 font-medium">Direct</div>
          </div>
        )}
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        {data.transitTime > 0
          ? `Est. ${data.transitTime} days`
          : data.transitTime === 0
          ? ' Est. Today'
          : '-'}
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        {data.transitTime > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment
                      .utc(data.projectedDeliveryDate)
                      .format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        {data.totalCharge === 0
          ? '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.totalCharge)}`
          : `$${getFormattedNumber(data.totalChargeUSD.toFixed(2))}`}
      </td>

      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        <div className="flex items-center gap-1">
          {data?.additionalCharges > 0 || data?.services?.length > 0 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={<ServiceDetail carrierServices={data} />}
            >
              {data.additionalCharges === 0
                ? '$0.00'
                : currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.additionalCharges)}`
                : `$${getFormattedNumber(data.additionalChargesUSD)}`}
            </ActionTooltip>
          ) : data.additionalCharges === 0 ? (
            '$0.00'
          ) : currency === CURRENCY.CAD ? (
            `$${getFormattedNumber(data.additionalCharges)}`
          ) : (
            `$${getFormattedNumber(data.additionalChargesUSD)}`
          )}
          {isQuoted ? (
            <TooltipCmp message="The carrier did not provide a rate for all additional services specified. Please validate the feasibility of the shipment and/or any additional costs.">
              <InfoCircle className="text-primary w-3 h-3" />
            </TooltipCmp>
          ) : (
            ''
          )}
        </div>
      </td>

      <td
        className={`px-5 py-4 text-sm ${isQuoted ? 'bg-utilityGray100' : ''}`}
      >
        <span className="text-textSecondary font-bold">
          {data.carrierQuoteId
            ? currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data.finalCharge)} CAD`
              : `$${getFormattedNumber(data.finalChargeUSD)} USD`
            : '-'}
        </span>
      </td>
      <td className={`px-5 py-4 ${isQuoted ? 'bg-utilityGray100' : ''}`}>
        <CheckBox
          checked={selectedCarrierRates.some(
            (form: any) => form.id === data.id
          )}
          onChangeFunc={() => {}}
          parentClassName=""
        />
      </td>
    </>
  );
};

export default WithRateQuote;
