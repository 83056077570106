import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import { useRolePermission } from 'src/utils/CommonFunctions';

const CustomerLoaderRow = () => {
  const { hasRoleV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4 min-w-44 max-w-44 w-44">
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
            J D
          </div>

          <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5 custom-loading">
            Culpa consectetur do laborum
          </span>
        </div>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <BadgeCmp
          style="modern"
          badgeDotColor="bg-utilityPink600"
          mainClassName="w-min-[100px] custom-loading"
        >
          prospect
        </BadgeCmp>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <div className="flex items-center min-w-92">
          <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none custom-loading">
            J D
          </div>
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate custom-loading">
              Anim laborum{' '}
            </h6>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <p className="hover:text-primary700 font-medium custom-loading">
          House Team
        </p>
      </td>
      <td className="px-5 py-3 font-normal min-w-44 max-w-44 w-44">
        <span className="custom-loading">MMM DD, YYYY</span>
      </td>
      <td className="px-5 py-3 font-normal min-w-44 max-w-44 w-44">
        <span className="custom-loading">MMM DD, YYYY</span>
      </td>
      <td className="px-5 py-3 font-normal min-w-44 max-w-44 w-44">
        <span className="custom-loading">MMM DD, YYYY</span>
      </td>
      {hasRoleV2('admin') && (
        <td className="px-5 py-4 min-w-16 max-w-16">
          <CheckBox
            parentClassName="w-3.5 h-3.5 custom-loading"
            onChangeFunc={() => {}}
          />
        </td>
      )}
    </>
  );
};

export default CustomerLoaderRow;
